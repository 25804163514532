import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nightOwl } from "react-syntax-highlighter/dist/esm/styles/prism";

const CodeBlock = props => {
  const codeString = props.children[0].children[0].value;
  const language = props.children[0].properties.className[0].split("-")[1];

  console.log(language);
  return (
    <SyntaxHighlighter
      customStyle={{
        marginTop: 32,
        marginBottom: 48,
        paddingTop: 32,
        paddingBottom: 32,
        borderRadius: 5
      }}
      language={language === "json" ? "json" : "jsx"}
      style={nightOwl}
    >
      {codeString}
    </SyntaxHighlighter>
  );
};

export default CodeBlock;
