import React, { useCallback } from "react";
import styled from "styled-components";

const Image = props => {
  const imgEl = useCallback(node => {
    if (node) {
      const pTag = node.parentNode;

      pTag.parentNode.replaceChild(node, pTag);
    }
  });
  return <StyledImage ref={imgEl} {...props} />;
};

const StyledImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  margin-bottom: 48px;
`;

export default Image;
